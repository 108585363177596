.footer {
    background-color: #2a1b15;
    position: relative;

    .logo-rodape {
        width: 92px;
        transition: all 0.5s ease;
        padding: 4rem 0rem 1rem 0rem;
        will-change: transform;

        @media (max-width: 991px) {
            padding: 2rem 0rem 0rem 0rem;
        }

        &:hover,
        &:focus {
            transform: scale(1.01);
        }
    }

    .box-conteudo-rodape {
        @media (max-width: 991px) {
            margin-bottom: 2rem;
        }

        .titulo-box-conteudo-rodape {
            font-family: "Carentro DEMO";
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            color: #ffffff;
            margin-bottom: 1.5rem;

            @media (max-width: 991px) {
                text-align: center;
                margin-bottom: 1rem;
            }
        }

        .link-box-conteudo-rodape {
            margin-bottom: 1rem;

            @media (max-width: 991px) {
                text-align: center;
            }

            a {
                font-size: 15px;
                font-style: normal;
                font-weight: 200;
                line-height: 23px;
                letter-spacing: 0em;
                color: #fff;
                transition: all 0.5s ease;

                &:hover,
                &:focus {
                    color: #b28740;
                }

                img {
                    width: 12px;
                }
            }
        }

        .link-redes-sociais-rodape {
            display: flex;
            align-items: center;

            @media (max-width: 991px) {
                justify-content: center;
            }

            .item-social {
                width: 41px;
                height: 41px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px #fff solid;
                transition: all 0.5s ease;
                background-color: #2a1b15;
                border-radius: 50%;

                &:hover,
                &:focus {
                    transform: translateY(-2px);
                }
            }
        }
    }

    .leaf1 {
        position: absolute;
        left: 2rem;
        bottom: 3rem;

        img {
            transform: scaleX(-1);
            width: 145px;

            @media (max-width: 539px) {
                width: 75px;
            }
        }
    }

    .leaf2 {
        position: absolute;
        right: 2rem;
        bottom: 3rem;

        img {
            width: 145px;

            @media (max-width: 539px) {
                width: 75px;
            }
        }
    }
}

.col-border {
    border-top: 1px rgba(255, 255, 255, 0.17) solid;
    padding: 1rem 15px;
}

.footer-2 {
    background-color: #2a1b15;
    color: #ffffff;
    font-size: 12px;
    font-style: normal;
    font-weight: 200;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;

    a {
        transition: all 0.5s ease;
        color: #ffffff;

        &:hover,
        &:focus {
            color: #b28740;
        }
    }
}

.botao-whatsapp {
    position: fixed;
    right: 15px;
    bottom: 75px;
    z-index: 3;
    opacity: 0;
    transition: all 0.5s ease;
    display: flex;
    flex-direction: column;

    @media (max-width: 991px) {
        border-radius: 50% !important;
        height: 50px !important;
        width: 50px !important;
    }

    a {
        font-family: "Rubik", sans-serif;
        margin: 5px 0px;
        color: #fff;
        transition: all 0.5s ease;
        border-radius: 6px;
        background-color: #00e492;
        border: 1px #00e492 solid;
        font-size: 18px;
        line-height: 21px;
        padding: 10.5px 25.5px;
        text-decoration: none !important;

        @media (max-width: 991px) {
            border-radius: 50% !important;
            font-size: 26px !important;
            padding: 8.8px 12.63px !important;
            text-align: center !important;
        }

        &:hover,
        &:focus {
            text-decoration: none !important;
            background-color: #fff;
            color: #00e492;
            transition: all 0.5s ease;
        }
    }
}

.botao-store {
    position: fixed;
    right: 15px;
    bottom: 22px;
    z-index: 3;
    opacity: 0;
    transition: all 0.5s ease;
    display: flex;
    flex-direction: column;

    @media (max-width: 991px) {
        border-radius: 50% !important;
        bottom: 8px !important;
        height: 50px !important;
        width: 50px !important;
    }

    a {
        font-family: "Rubik", sans-serif;
        margin: 5px 0px;
        color: #fff;
        transition: all 0.5s ease;
        border-radius: 6px;
        background-color: #f3bc55;
        border: 1px #f3bc55 solid;
        font-size: 18px;
        line-height: 21px;
        padding: 10.5px 21px;
        text-decoration: none !important;

        @media (max-width: 991px) {
            border-radius: 50% !important;
            font-size: 26px !important;
            padding: 8.8px 12.63px !important;
            text-align: center !important;
        }

        &:hover,
        &:focus {
            text-decoration: none !important;
            background-color: #fff;
            color: #f3bc55;
            transition: all 0.5s ease;
        }
    }
}

.padding-rodape-row {
    padding: 2rem 0rem;

    @media (min-width: 992px) {
        padding: 5rem 0rem;
    }
}
.footer-lgpd {
    position: fixed;
    z-index: 99999;
    max-width: 1230px;
    transition: 0.3s ease-in-out;
    background: #fff;
    bottom: 25px;
    left: 10px;
    right: 10px;
    padding: 20px;
    box-shadow: 0 0 10px #aaa;
    border-radius: 12px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto;
}
.footer-lgpd-opened {
    transition: 0.1s ease-in-out;
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0);
}
.btn-lgpd {
    background-color: #2a1b15;
    color: #fff !important;
    text-align: center;
    border: 1px #2a1b15 solid;
    transition: all 0.5s ease;
    font-weight: 500;
    width: 300px;
    padding: 10px 15px;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 1px;
    border-radius: 1.25rem;
    text-decoration: none !important;
}
.btn-lgpd:hover,
.btn-lgpd:focus {
    background-color: white !important;
    color: #2a1b15 !important;
    transition: all 0.5s ease;
}
.label-lgpd {
    color: white;
}
