.section-filtros{
    background-color: #5B1E20;
    padding: 25px 0px;

    .box-filtros{

        form{
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media(max-width: 991px){
                flex-direction: column;
            }

            select{
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 23px;
                letter-spacing: 0em;
                color: #2A1B15;
                padding: 0px 20px;
                height: 46px;
                border-radius: 0px !important;
                -moz-appearance:none;
                -webkit-appearance:none;
                appearance:none;
                background-image: url('/images/select-arrow.png');
                background-size: 13px;
                background-position: calc(100% - 20px) calc(50%);
                background-repeat: no-repeat;
                width: 100%;

                *{
                    border-top: 1px #000 solid;
                }
            }

             button{
                height: 46px;
            }

            .form-group{
                width: 233px;
                margin-bottom: 0 !important;
            }

            .margem-form{
                @media(min-width: 992px){
                    margin-right: 15px;
                }

                @media(max-width: 991px){
                    margin-bottom: 15px !important;
                }
            }
        }

        button{
            background-color: #B28740;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            color: #2A1B15;
            border: 1px solid #B28740;
            width: 100%;
            transition: all 0.5s ease;

            &:hover, &:focus{
                color: #B28740;
                background-color: #2A1B15;
            }
        }
    }
}

.section-pagina-produtos{
    margin: 80px 0px;

    @media(max-width: 991px){
        margin: 30px 0px;
    }

    .color-changed{
        background-image: url('/images/stripes.png');
        background-size: cover;
        padding-bottom: 40px;

        @media(max-width: 991px){
            padding-bottom: 30px;
        }
    }

    .box-produto-pagina{
        margin-top: 60px;
        padding-bottom: 10px;

        @media(max-width: 991px){
            margin-top: 30px;
        }

        .imagem-produto-home{
            background-size: contain;
            padding-top: 268px;
            background-repeat: no-repeat;
            background-position: center;
            margin-bottom: 10px;
        }

        .titulo-produto-home{
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0em;
            text-align: center;
            color: #2A1B15;
            margin-bottom: 10px;
        }

        .detalhes-produto-home{
            margin-bottom: 15px;

            .detalhe{
                font-size: 11px;
                font-style: normal;
                font-weight: 300;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: center;
                color: #2A1B15;
            }
        }

        .bandeiras-produto-home{
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            .bandeira{
                img{
                    width: 25px;
                    margin: 0 5px;
                    border-radius: 50%;
                }
            }
        }

        .link-produto-home{
            text-align: center;

            a{
                font-size: 15px;
                font-style: normal;
                font-weight: 300;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: center;
                border: 1px #5B1E20 solid;
                color: #5B1E20;
                background-color: #fff;
                transition: all 0.5s ease;
                padding: 9.5px 32px;

                &:hover, &:focus{
                    background-color: #5B1E20;
                    color: #fff;

                    img{
                        filter: brightness(0) invert(1);
                    }
                }

                img{
                    margin-left: 0px;
                    transition: all 0.5s ease;
                    width: 16px;
                }
            }
        }
    }
}
