.section-resumo-sobre {
    background: rgba(233, 219, 206, 255);
    padding: 80px 0px;
    position: relative;

    .titulo-section-resumo {
        .imagem-titulo {
            text-align: center;

            img {
                width: 129px;
            }
        }

        .texto-titulo {
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: center;
            color: #5b1e20;
            margin: 10px 0px;
        }

        .texto-titulo-2 {
            font-family: "Carentro DEMO";
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: 33px;
            letter-spacing: 0em;
            text-align: center;
            color: #2a1b15;
        }
    }

    @media (max-width: 991px) {
        padding: 30px 0px;
    }

    .texto-sobre {
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: center;
        color: #2a1b15;
        margin-top: 47px;

        @media (max-width: 991px) {
            margin-top: 30px;
        }
    }
}

.section-nossa-historia-sobre {
    position: relative;
    margin-top: 50px;

    @media (max-width: 991px) {
        margin-top: 30px;
    }

    .bigleaf {
        position: absolute;
        left: 50px;
        width: 241px;
        transform: rotate(180deg);

        @media (max-width: 991px) {
            width: 75px;
            left: 15px;
        }

        @media (min-width: 1366px) and (max-width: 1599px) {
            left: 85px;
        }

        @media (min-width: 1600px) and (max-width: 1919px) {
            left: 100px;
        }

        @media (min-width: 1920px) and (max-width: 2559px) {
            left: 150px;
        }

        @media (min-width: 2560px) {
            left: 200px;
        }
    }

    .titulo-section-nossa-historia {
        margin-top: 30px;
        margin-bottom: 80px;

        @media (max-width: 991px) {
            margin-bottom: 30px;
        }

        .texto-titulo {
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            color: #5b1e20;
            margin: 10px 0px;
        }

        .texto-titulo-2 {
            font-family: "Carentro DEMO";
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: 33px;
            letter-spacing: 0em;
            text-align: left;
            color: #2a1b15;
        }
    }

    .box-foto-historia {
        .imagem-historia {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            padding-top: 450px;
            transition: all 0.5s ease;
            cursor: pointer;

            @media (min-width: 1200px) and (max-width: 1919px) {
                padding-top: 400px;
            }

            @media (max-width: 991px) {
                padding-top: 300px;
            }

            &:hover,
            &:focus {
                transform: translateY(-1px);
            }
        }
    }

    .passadores {
        display: flex;
        align-items: center;

        @media (max-width: 991px) {
            margin-bottom: 30px;
        }

        @media (min-width: 992px) {
            margin-right: 15%;
        }

        .next-historia {
            cursor: pointer;
            width: 40px;
            height: 40px;
            vertical-align: middle;
            justify-content: center;
            align-items: center;
            z-index: 2;
            display: flex;
            background-color: transparent;
            transition: all 0.5s ease;
            border: 1px #5b1e20 solid;

            &:hover,
            &:focus {
                background-color: #5b1e20;

                img {
                    filter: brightness(0) invert(1);
                }
            }

            img {
                width: 16px;
            }
        }

        .prev-historia {
            cursor: pointer;
            width: 40px;
            height: 40px;
            vertical-align: middle;
            justify-content: center;
            align-items: center;
            z-index: 2;
            display: flex;
            background-color: transparent;
            transition: all 0.5s ease;
            border: 1px #5b1e20 solid;
            transform: rotate(180deg);
            margin-right: 21px;

            &:hover,
            &:focus {
                background-color: #5b1e20;

                img {
                    filter: brightness(0) invert(1);
                }
            }

            img {
                width: 16px;
            }
        }
    }
}

.section-loja-virtual {
    margin-top: 80px;

    @media (max-width: 991px) {
        margin-top: 30px;
    }

    .box-vinho {
        height: 426px;
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        @media (max-width: 991px) {
            height: 350px;
        }

        .mascara-vinho {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.35);
            display: flex;
            align-items: center;
            justify-content: center;

            .flex-vinho {
                .titulo-vinho {
                    font-family: "Carentro DEMO";
                    font-size: 41px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 54px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #fff;
                    margin-bottom: 20px;
                }

                .link-vinho {
                    text-align: center;

                    a {
                        font-family: "Poppins", sans-serif;
                        padding: 9.5px 32px;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 23px;
                        letter-spacing: 0em;
                        text-align: center;
                        color: #e1cdbc;
                        border: 1px solid #e1cdbc;
                        transition: all 0.5s ease;

                        &:hover,
                        &:focus {
                            padding-right: 27px;

                            img {
                                margin-left: 5px;
                            }
                        }

                        img {
                            width: 16px;
                            margin-left: 0px;
                            transition: all 0.5s ease;
                        }
                    }
                }
            }
        }
    }
}

.section-catalogo-sobre {
    margin-top: 120px;
    margin-bottom: 80px;

    @media (max-width: 991px) {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .container-color {
        background-color: rgba(178, 135, 64, 0.14);
    }

    .titulo-catalogo {
        padding: 45px 0px;

        @media (max-width: 991px) {
            padding: 30px 0px;
        }

        .text-titulo-catalogo {
            font-family: "Carentro DEMO";
            font-size: 41px;
            font-style: normal;
            font-weight: 400;
            line-height: 54px;
            letter-spacing: 0em;
            text-align: left;
            color: #5b1e20;
            position: relative;

            @media (max-width: 991px) {
                text-align: center;
            }

            .acute {
                position: absolute;
                left: 89px;
                top: -27.5px;

                img {
                    width: 7px;
                }
            }
        }

        .link-catalogo {
            margin-top: 7.5px;

            a {
                font-size: 15px;
                font-style: normal;
                font-weight: 300;
                line-height: 23px;
                letter-spacing: 0em;
                color: #b28740;
                transition: all 0.5s ease;
                display: flex;
                align-items: center;

                @media (max-width: 991px) {
                    justify-content: center;
                }

                &:hover,
                &:focus {
                    color: #5b1e20;

                    img {
                        filter: brightness(0);
                    }
                }

                img {
                    transition: all 0.5s ease;
                    width: 14px;
                }
            }
        }
    }

    .box-img-garrafas {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            margin-top: -60px;
            margin-bottom: 5px;
            max-width: 350px;

            @media (max-width: 991px) {
                margin-top: 0px;
                max-width: 250px;
                margin-bottom: 30px;
            }

            @media (min-width: 992px) {
                margin-left: 115px;
            }
        }
    }
}
