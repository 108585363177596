.section-produto-title{
    background-color: #5B1E20;

    .breadcrumb-produto{
        padding: 36px 0px;
        color: #fff;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        line-height: 23px;
        letter-spacing: 0em;

        a{
            font-size: 15px;
            font-style: normal;
            font-weight: 300;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            color: #fff;
            transition: all 0.5s ease;

            &:hover, &:focus{
                color: #B28740;
            }
        }
    }
}


.section-dados-produto{
    margin: 80px 0px;

    @media(max-width: 991px){
        margin: 30px 0px;
    }

    .foto-produto-principal{
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        padding-top: 550px;
        cursor: pointer;
        transition: all 0.5s ease;

        @media(max-width: 991px){
            padding-top: 350px;
        }

        &:hover, &:focus{
            transform: scale(1.01);
        }
    }

    .dados-produto{

        .title{
            font-family: "Carentro DEMO";
            font-size: 41px;
            font-style: normal;
            font-weight: 400;
            line-height: 54px;
            letter-spacing: 0em;
            color: #2A1B15;
            margin-bottom: 35px;

            @media(max-width: 991px){
                margin-bottom: 15px;
                margin-top: 15px;
            }
        }

        .details{
            font-size: 15px;
            font-style: normal;
            font-weight: 300;
            line-height: 33px;
            letter-spacing: 0em;
            text-align: justify;
            color: #2A1B15;

            span{
                font-weight: 500;
            }
        }

        .bandeira-tipo{
            display: flex;
            margin: 35px 0px;

            @media(max-width: 991px){
                margin: 15px 0px;
            }

            .item-bandeira{
                margin-right: 25px;

                img{
                    width: 25px;
                    margin-right: 7px;
                }
            }
        }

        .abstract{
            font-size: 15px;
            font-style: italic;
            font-weight: 300;
            line-height: 33px;
            letter-spacing: 0em;
            text-align: justify;
            color: #2A1B15;
            margin-bottom: 35px;

            @media(max-width: 991px){
                margin-bottom: 30px;
            }
        }

        .download-ficha{
            a{
                button{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #fff;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 23px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #5B1E20;
                    border: 1px solid #5B1E20;
                    width: 198px;
                    height: 42px;
                    transition: all 0.5s ease;

                    @media(max-width: 991px){
                        margin: 0px auto;
                    }

                    &:hover, &:focus{
                        color: #fff;
                        background-color: #5B1E20;

                        img{
                            filter: brightness(0) invert(1);
                        }
                    }

                    img{
                        transition: all 0.5s ease;
                        width: 14px;
                        margin-left: 5px;
                    }
                }
            }
        }

        .redes-sociais-produto{
            display: flex;
            align-items: center;
            margin-top: 35px;

            @media(max-width: 991px){
                margin-top: 30px;
                justify-content: center;
            }

            .item-rede{
                margin-right: 11px;

                a{
                    button{
                        width: 41px;
                        height: 41px;
                        border: 0.92px solid #5B1E20;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: all 0.5s ease;
                        background-color: #fff;
                        color: #5B1E20;
                        font-size: 18px;

                        &:hover, &:focus{
                            background-color: #5B1E20;
                            color: #fff;

                            img{
                                filter: brightness(0) invert(1);
                            }
                        }

                        img{
                            transition: all 0.5s ease;
                        }

                        .width-facebook{
                            width: 9px;
                        }

                        .width-restante{
                            width: 18px
                        }
                    }
                }
            }
        }
    }
}

.section-produtos-relacionados{
    margin-top: 80px;

    @media(max-width: 991px){
        margin-top: 30px;
    }

    .prev-conheca {
        position: absolute;
        top: calc(50% - 20px);
        left: -58px;
        cursor: pointer;
        width: 40px;
        height: 40px;
        display: flex;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease;
        background-color: transparent;
        border: 1px #5B1E20 solid;
        z-index: 2;

        @media(max-width: 1365px) {
            left: 15px;
        }

        &:hover, &:focus{
            background-color: #5B1E20;

            img{
                filter: brightness(0) invert(1);
            }
        }

        img {
            transform: rotate(180deg);
            width: 16px;
        }
    }

    .next-conheca {
        position: absolute;
        top: calc(50% - 20px);
        right: -58px;
        cursor: pointer;
        width: 40px;
        height: 40px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        z-index: 2;
        display: flex;
        background-color: transparent;
        transition: all 0.5s ease;
        border: 1px #5B1E20 solid;

        @media(max-width: 1365px) {
            right: 15px;
        }

        &:hover, &:focus{
            background-color: #5B1E20;

            img{
                filter: brightness(0) invert(1);
            }
        }

        img {
            width: 16px;
        }
    }

    .color-changed{
        border-top: 1px #C4C4C470 solid;
        padding: 80px 15px 143px 15px;


        @media(max-width: 991px){
            padding: 30px 15px 83px 15px;
        }
    }

    .titulo-section-conheca{

        .imagem-titulo{
            text-align: center;

            img{
                width: 129px;
            }
        }

        .texto-titulo{
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: center;
            color: #5B1E20;
            margin: 10px 0px;
        }

        .texto-titulo-2{
            font-family: 'Carentro DEMO';
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: 33px;
            letter-spacing: 0em;
            text-align: center;
            color: #2A1B15;
        }
    }

    .box-produto-home{
        margin-top: 40px;
        padding-bottom: 10px;

        @media(max-width: 991px){
            margin-top: 30px;
        }

        .imagem-produto-home{
            background-size: contain;
            padding-top: 268px;
            background-repeat: no-repeat;
            background-position: center;
            margin-bottom: 10px;
        }

        .titulo-produto-home{
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0em;
            text-align: center;
            color: #2A1B15;
            margin-bottom: 10px;
        }

        .detalhes-produto-home{
            margin-bottom: 15px;

            .detalhe{
                font-size: 11px;
                font-style: normal;
                font-weight: 300;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: center;
                color: #2A1B15;
            }
        }

        .bandeiras-produto-home{
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            .bandeira{
                img{
                    width: 25px;
                    margin: 0 5px;
                    border-radius: 50%;
                }
            }
        }

        .link-produto-home{
            text-align: center;

            a{
                font-size: 15px;
                font-style: normal;
                font-weight: 300;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: center;
                border: 1px #5B1E20 solid;
                color: #5B1E20;
                background-color: #fff;
                transition: all 0.5s ease;
                padding: 9.5px 32px;

                &:hover, &:focus{
                    color: #fff;
                    background-color: #5B1E20;

                    img{
                        filter: brightness(0) invert(1);
                    }
                }

                img{
                    margin-left: 0px;
                    transition: all 0.5s ease;
                    width: 16px;
                }
            }
        }
    }

    .container-carrossel-conheca{
        position: relative;

        .tns-nav{
            position: absolute;
            bottom: -63px;
            z-index: 2;
            width: 100%;
            justify-content: center;
            display: flex;
            align-items: center;

            @media(max-width: 991px){
                bottom: -33px;
            }

            button{
                background-color: #C4C4C4;
                width: 44px;
                height: 2px;
                margin: 0 6.5px;
                border: none !important;
            }

            .tns-nav-active{
                background-color: #B28740 !important;
                transition: all 0.5s ease;
            }
        }
    }
}
