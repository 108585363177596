.nav-item {
    @media (min-width: 992px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.chevron-down-image {
    width: 13px;
}

.cabecalho {
    @media (max-width: 991px) {
        background-color: #2a1b15;
    }

    @media (min-width: 992px) {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
    }

    .navbar-brand {
        @media (max-width: 991px) {
            margin: 10px 0px;
        }
    }

    .nivel-2 {
        background-color: transparent !important;

        .topo {
            padding: 1.5rem 0rem !important;

            @media (max-width: 991px) {
                padding: 1rem 0rem !important;
            }

            .logomarca {
                max-width: 130px;
                will-change: transform;
                transition: all 0.5s ease;

                &:hover,
                &:focus {
                    transform: scale(1.02);
                    transition: all 0.5s ease;
                }
            }
            .links-navbar {
                a {
                    font-family: "Poppins", sans-serif;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 23px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #fff;
                    transition: all 0.5s ease;
                    padding: 0.5rem 1.75rem;
                    position: relative;

                    @media (min-width: 992px) and (max-width: 1199px) {
                        padding: 0.5rem 1rem;
                    }
                }
            }
            .dropdown-menu-topo {
                border-top: 1px #5b1e20 solid;
                border-left: none;
                border-right: none;
                border-bottom: none;
                min-width: 100%;
                border-radius: 0px !important;
                padding: 0px;
                margin: 0 !important;

                @media (max-width: 991px) {
                    margin-top: 15px !important;
                    box-shadow: none !important;
                    background-color: transparent !important;
                }

                @media (min-width: 992px) {
                    top: 40px;
                }

                a,
                button {
                    font-family: "Poppins", sans-serif;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 23px;
                    letter-spacing: 0em;
                    text-align: left;
                    transition: all 0.5s ease;
                    padding: 0.48rem 1.5rem;
                    border-bottom: 1px #5b1e20 solid;
                    color: #5b1e20;
                    background-color: #fff;

                    @media (max-width: 991px) {
                        background-color: transparent;
                        color: #fff;
                        border-bottom: 1px #5b1e2080 solid;
                    }

                    &:hover,
                    &:focus,
                    &:active {
                        color: #fff;
                        background-color: #5b1e20;
                    }
                }
            }
        }
    }
}

.cabecalho-2 {
    background-color: #2a1b15;

    .navbar-brand {
        @media (max-width: 991px) {
            margin: 0px;
        }
    }

    .nivel-2 {
        .topo {
            @media (max-width: 991px) {
                padding: 0.5rem;
            }

            @media (min-width: 992px) {
                padding: 2.5px !important;
            }

            .logomarca {
                max-width: 71px;
                will-change: transform;
                transition: all 0.5s ease;
                &:hover,
                &:focus {
                    transform: scale(1.02);
                    transition: all 0.5s ease;
                }
            }
            .links-navbar {
                a {
                    font-family: "Poppins", sans-serif;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 23px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #fff;
                    transition: all 0.5s ease;
                    padding: 0.5rem 1.75rem;
                    position: relative;

                    @media (min-width: 992px) and (max-width: 1199px) {
                        padding: 0.5rem 1rem;
                    }
                }
            }
            .dropdown-menu-topo {
                border-top: 1px #5b1e20 solid;
                border-left: none;
                border-right: none;
                border-bottom: none;
                min-width: 100%;
                border-radius: 0px !important;
                padding: 0px;
                margin: 0 !important;

                @media (max-width: 991px) {
                    margin-top: 15px !important;
                    background-color: transparent;
                    box-shadow: none;
                }

                @media (min-width: 992px) {
                    top: 40px;
                }

                a,
                button {
                    font-family: "Poppins", sans-serif;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 23px;
                    letter-spacing: 0em;
                    text-align: left;
                    transition: all 0.5s ease;
                    padding: 0.48rem 1.5rem;
                    border-bottom: 1px #5b1e20 solid;
                    color: #5b1e20;
                    background-color: #fff;

                    @media (max-width: 991px) {
                        color: #fff;
                        background-color: transparent;
                        border-bottom: 1px #5b1e2080 solid;
                    }

                    &:hover,
                    &:focus,
                    &:active {
                        color: #fff;
                        background-color: #5b1e20;
                    }
                }
            }
        }
    }
}

.et_pb_menu__search-container,
.et_pb_menu .et_pb_menu__search-container {
    @media (max-width: 991px) {
        position: relative;
    }
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-pack: stretch;
    -ms-flex-pack: stretch;
    justify-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    bottom: 0;
    width: 100%;
    height: 0px;
    opacity: 0;
    visibility: hidden;
    display: flex;
    transition: all 0.5s ease;
    color: #fff !important;
}

.et_pb_menu__search {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: stretch;
    -ms-flex-pack: stretch;
    justify-content: stretch;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    form {
        width: 100%;
    }
}

.formbuscador {
    display: flex;
    align-items: center;
    i {
        cursor: pointer;
        transition: all 0.5s ease;
        margin-right: 10px;
        &:hover,
        &:focus {
            transition: all 0.5s ease;
        }
    }
}

.padding-buscador {
    padding-top: 0px;
    padding-left: 0rem 15.5rem;

    @media (max-width: 991px) {
        padding: 0rem;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
        padding: 0rem 4rem;
    }
    @media (min-width: 1200px) and (max-width: 1599px) {
        padding: 0rem 9rem;
    }
    @media (min-width: 1600px) {
        padding: 0rem 14rem;
    }
}

.color-and-size-search {
    font-size: 1.25rem;
    i {
        cursor: pointer;
    }
}

.btn-search-color {
    text-align: center;
    font-size: 15px;
    border-radius: 10.49px;
    transition: all 0.5s ease;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #fff !important;

    &:hover,
    &:focus {
        transition: all 0.5s ease;
    }
}

.botao-topo-menu {
    @media (max-width: 991px) {
        margin: 0rem 1.5rem;
    }
}

.height-ul {
    min-height: 157px;
}

.height-ul2 {
    min-height: 95px;
}

.mascara-sem-banner {
    @media (min-width: 992px) {
        background-image: url("/images/mask_banner.png");
        background-size: cover;
        position: relative !important;
    }
}

.link-ativo {
    &:after {
        content: "";
        background-color: #b28740;
        height: 4px;
        border-radius: 8px;
        position: absolute;
        left: calc(50% - 7.5px);
        right: calc(50% - 7.5px);
        padding: 0px 7.5px;
        transition: all 0.5s ease;
        bottom: 0px;
        margin: 0;
        border: 0;
    }
}

.efeito-active {
    &:after {
        content: "";
        background-color: #b28740;
        padding: 0;
        height: 4px;
        border-radius: 8px;
        position: absolute;
        left: 50%;
        right: 50%;
        transition: all 0.5s ease;
        bottom: 0px;
        margin: 0;
        border: 0;
    }

    &:hover,
    &:focus {
        &:after {
            left: calc(50% - 7.5px);
            right: calc(50% - 7.5px);
            padding: 0px 7.5px;
        }
    }
}

.efeito-active-hover {
    &:after {
        content: "";
        background-color: #b28740;
        padding: 0;
        height: 4px;
        border-radius: 8px;
        position: absolute;
        left: 50%;
        right: 50%;
        transition: all 0.5s ease;
        bottom: 0px;
        margin: 0;
        border: 0;
    }

    &:hover {
        &:after {
            left: calc(50% - 7.5px);
            right: calc(50% - 7.5px);
            padding: 0px 7.5px;
        }
    }
}

.fa-search-size {
    font-size: 15px !important;
}

.openDrop {
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    transition: all 0.5s ease;
    padding: 0.5rem 1.75rem;
    position: relative;

    img {
        will-change: transform;
        transition: all 0.5s ease;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        padding: 0.5rem 1rem;
    }

    &:hover,
    &:focus {
        img {
            transform: rotate(180deg);
        }

        .dropInterno {
            display: block;
        }
    }

    .dropInterno {
        padding-top: 9px !important;
        border-top: none !important;
        background-color: transparent !important;
    }
}

.efeito-seta-mobile {
    img {
        will-change: transform;
        transition: all 0.5s ease;
    }

    &:hover,
    &:focus {
        img {
            transform: rotate(180deg);
        }
    }
}
