.section-seja-revendedor{

    .titulo-section-revendedor{
        padding: 80px 0px;

        @media(max-width: 991px){
            padding: 30px 0px;
        }

        .imagem-titulo{
            text-align: center;

            img{
                width: 129px;
            }
        }

        .texto-titulo{
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: center;
            color: #5B1E20;
            margin: 10px 0px;
        }

        .texto-titulo-2{
            font-family: 'Carentro DEMO';
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: 33px;
            letter-spacing: 0em;
            text-align: center;
            color: #2A1B15;
        }
    }

    .box-seja-revendedor{
        margin-bottom: 80px;

        @media(max-width: 991px){
            margin-bottom: 30px;
        }

        form{

            .form-group{
                margin-bottom: 10px !important;

                input{
                    height: 41px;
                }

                textarea{
                    height: 122px;
                }

                input, textarea{
                    border: 1px solid rgba(149, 149, 149, 0.6);
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 23px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #959595;
                    border-radius: 0px;
                    padding: 10px 25px;

                    &::placeholder{
                        color: #959595;
                    }
                }
            }

            .send{
                margin-top: 40px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                @media(max-width: 991px){
                    margin-top: 30px;
                }

                button{
                    width: 222px;
                    height: 42px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 23px;
                    letter-spacing: 0em;
                    border: 1px solid #5B1E20;
                    background-color: #fff;
                    color: #5B1E20;
                    transition: all 0.5s ease;

                    &:hover, &:focus{
                        background-color: #5B1E20;
                        color: #fff;

                        img{
                            filter: brightness(0) invert(1);
                        }
                    }

                    img{
                        transition: all 0.5s ease;
                        width: 16px;
                    }
                }
            }
        }
    }
}
