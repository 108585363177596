.container-banner {
    position: relative;

    .descricao-banner {
        overflow: hidden;
        font-family: "Carentro DEMO";
        font-size: 41px;
        font-style: normal;
        font-weight: 400;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: center;
        z-index: 2;
        color: #fff;
        padding: 5rem;
        opacity: 0;
        transform: translateY(80px);
        transition: all 0.5s ease;

        @media (max-width: 767px) {
            font-size: 20px;
            line-height: 33px;
            padding: 5rem 3rem;
        }

        @media (min-width: 768px) and (max-width: 991px) {
            font-size: 35px;
            line-height: 48px;
        }

        .link-banner {
            margin-top: 1rem;

            a {
                font-family: "Poppins", sans-serif;
                padding: 0.6rem 1.61rem;
                font-size: 15px;
                font-style: normal;
                font-weight: 300;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: center;
                color: #e1cdbc;
                border: 1px solid #e1cdbc;
                transition: all 0.5s ease;

                @media (max-width: 767px) {
                    padding: 0.5rem;
                }

                &:hover,
                &:focus {
                    padding-right: calc(1.61rem - 5px);

                    @media (max-width: 767px) {
                        padding-right: calc(1.25rem - 5px);
                    }

                    img {
                        margin-left: 5px;
                    }
                }

                img {
                    width: 16px;
                    margin-left: 0px;
                    transition: all 0.5s ease;
                }
            }
        }
    }

    .imagem {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        height: 100vh;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 991px) {
            height: 220px;
        }

        .mask-imagem {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: url("/images/mask_banner.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }

    .passadores {
        position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: calc(50% - 20px);

        .container {
            position: absolute;
        }
    }

    .prev {
        position: absolute;
        left: -58px;
        cursor: pointer;
        width: 40px;
        height: 40px;
        top: 0;
        display: flex;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease;
        background-color: transparent;
        border: 1px #e1cdbc solid;
        z-index: 2;

        @media (max-width: 1365px) {
            left: 10px;
        }

        &:hover,
        &:focus {
            background-color: #e1cdbc;

            img {
                filter: brightness(1) invert(1);
            }
        }

        img {
            transform: rotate(180deg);
            width: 16px;
        }
    }

    .next {
        position: absolute;
        right: -58px;
        top: 0;
        cursor: pointer;
        width: 40px;
        height: 40px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        background-color: transparent;
        transition: all 0.5s ease;
        border: 1px #e1cdbc solid;
        z-index: 2;

        @media (max-width: 1365px) {
            right: 10px;
        }

        &:hover,
        &:focus {
            background-color: #e1cdbc;

            img {
                filter: brightness(1) invert(1);
            }
        }

        img {
            width: 16px;
        }
    }

    .tns-nav {
        position: absolute;
        bottom: 3rem;
        z-index: 2;
        width: 100%;
        justify-content: center;
        display: flex;
        align-items: center;

        @media (max-width: 991px) {
            bottom: 2rem;
        }

        button {
            background-color: #c4c4c4;
            width: 44px;
            height: 2px;
            margin: 0 6.5px;
            border: none !important;
        }

        .tns-nav-active {
            background-color: #b28740 !important;
            transition: all 0.5s ease;
        }
    }
}

.section-conheca-nossos-produtos {
    margin-top: 80px;

    @media (max-width: 991px) {
        margin-top: 30px;
    }

    .prev-conheca {
        position: absolute;
        top: calc(50% - 20px);
        left: -58px;
        cursor: pointer;
        width: 40px;
        height: 40px;
        display: flex;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease;
        background-color: transparent;
        border: 1px #5b1e20 solid;
        z-index: 2;

        @media (max-width: 1365px) {
            left: 15px;
        }

        &:hover,
        &:focus {
            background-color: #5b1e20;

            img {
                filter: brightness(0) invert(1);
            }
        }

        img {
            transform: rotate(180deg);
            width: 16px;
        }
    }

    .next-conheca {
        position: absolute;
        top: calc(50% - 20px);
        right: -58px;
        cursor: pointer;
        width: 40px;
        height: 40px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        z-index: 2;
        display: flex;
        background-color: transparent;
        transition: all 0.5s ease;
        border: 1px #5b1e20 solid;

        @media (max-width: 1365px) {
            right: 15px;
        }

        &:hover,
        &:focus {
            background-color: #5b1e20;

            img {
                filter: brightness(0) invert(1);
            }
        }

        img {
            width: 16px;
        }
    }

    .color-changed {
        background-image: url("/images/stripes.png");
        background-size: cover;
        padding: 50px 15px 123px 15px;

        @media (max-width: 991px) {
            padding: 30px 15px 63px 15px;
        }
    }

    .titulo-section-conheca {
        .imagem-titulo {
            text-align: center;

            img {
                width: 129px;
            }
        }

        .texto-titulo {
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: center;
            color: #5b1e20;
            margin: 10px 0px;
        }

        .texto-titulo-2 {
            font-family: "Carentro DEMO";
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: 33px;
            letter-spacing: 0em;
            text-align: center;
            color: #2a1b15;
        }
    }

    .box-produto-home {
        margin-top: 40px;
        padding-bottom: 10px;

        @media (max-width: 991px) {
            margin-top: 30px;
        }

        .imagem-produto-home {
            background-size: contain;
            padding-top: 268px;
            background-repeat: no-repeat;
            background-position: center;
            margin-bottom: 10px;
        }

        .titulo-produto-home {
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0em;
            text-align: center;
            color: #2a1b15;
            margin-bottom: 10px;
        }

        .detalhes-produto-home {
            margin-bottom: 15px;

            .detalhe {
                font-size: 11px;
                font-style: normal;
                font-weight: 300;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: center;
                color: #2a1b15;
            }
        }

        .bandeiras-produto-home {
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            .bandeira {
                img {
                    width: 25px;
                    margin: 0 5px;
                    border-radius: 50%;
                }
            }
        }

        .link-produto-home {
            text-align: center;

            a {
                font-size: 15px;
                font-style: normal;
                font-weight: 300;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: center;
                border: 1px #5b1e20 solid;
                color: #5b1e20;
                background-color: #fff;
                transition: all 0.5s ease;
                padding: 9.5px 32px;

                &:hover,
                &:focus {
                    color: #fff;
                    background-color: #5b1e20;

                    img {
                        filter: brightness(0) invert(1);
                    }
                }

                img {
                    margin-left: 0px;
                    transition: all 0.5s ease;
                    width: 16px;
                }
            }
        }
    }

    .container-carrossel-conheca {
        position: relative;

        .tns-nav {
            position: absolute;
            bottom: -63px;
            z-index: 2;
            width: 100%;
            justify-content: center;
            display: flex;
            align-items: center;

            @media (max-width: 991px) {
                bottom: -33px;
            }

            button {
                background-color: #c4c4c4;
                width: 44px;
                height: 2px;
                margin: 0 6.5px;
                border: none !important;
            }

            .tns-nav-active {
                background-color: #b28740 !important;
                transition: all 0.5s ease;
            }
        }
    }
}

.section-nossa-historia {
    position: relative;
    margin-top: 50px;

    @media (max-width: 991px) {
        margin-top: 30px;
    }

    .bigleaf {
        position: absolute;
        left: 50px;
        width: 241px;

        @media (max-width: 991px) {
            width: 75px;
            left: 15px;
        }

        @media (min-width: 1366px) and (max-width: 1599px) {
            left: 85px;
        }

        @media (min-width: 1600px) and (max-width: 1919px) {
            left: 100px;
        }

        @media (min-width: 1920px) and (max-width: 2559px) {
            left: 150px;
        }

        @media (min-width: 2560px) {
            left: 200px;
        }
    }

    .leaf {
        top: 30px;
        position: absolute;
        right: 70px;
        width: 112px;

        @media (max-width: 991px) {
            width: 55px;
            right: 15px;
        }

        @media (min-width: 1366px) and (max-width: 1559px) {
            right: 115px;
        }

        @media (min-width: 1600px) and (max-width: 1919px) {
            right: 100px;
        }

        @media (min-width: 1920px) and (max-width: 2559px) {
            right: 260px;
        }

        @media (min-width: 2560px) {
            right: 580px;
        }
    }

    .titulo-section-nossa-historia {
        margin-top: 30px;
        margin-bottom: 80px;

        @media (max-width: 991px) {
            margin-bottom: 30px;
        }

        .imagem-titulo {
            text-align: center;

            img {
                width: 129px;
            }
        }

        .texto-titulo {
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: center;
            color: #5b1e20;
            margin: 10px 0px;
        }

        .texto-titulo-2 {
            font-family: "Carentro DEMO";
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: 33px;
            letter-spacing: 0em;
            text-align: center;
            color: #2a1b15;
        }
    }

    .nossa-historia {
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: justify;
        color: #2a1b15;
        padding-bottom: 9px;
        padding-left: 20px;
        padding-right: 20px;

        .ver-mais-historia {
            text-align: center;
            margin-top: 39.5px;

            @media (max-width: 991px) {
                margin-bottom: 30px;
                margin-top: 30px;
            }

            a {
                font-size: 15px;
                font-style: normal;
                font-weight: 300;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: center;
                border: 1px #5b1e20 solid;
                color: #5b1e20;
                transition: all 0.5s ease;
                padding: 9.5px 32px;

                &:hover,
                &:focus {
                    color: #fff;
                    background: #5b1e20;

                    img {
                        filter: brightness(0) invert(1);
                    }
                }

                img {
                    margin-left: 0px;
                    transition: all 0.5s ease;
                    width: 16px;
                }
            }
        }
    }

    .box-foto-historia {
        .imagem-historia {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            padding-top: 450px;
            transition: all 0.5s ease;

            @media (min-width: 1200px) and (max-width: 1919px) {
                padding-top: 400px;
            }

            @media (max-width: 991px) {
                padding-top: 300px;
            }

            &:hover,
            &:focus {
                transform: translateY(-1px);
            }
        }
    }

    .container-carrossel-historia {
        position: relative;

        .next-historia {
            position: absolute;
            top: calc(50% - 20px);
            right: 50px;
            cursor: pointer;
            width: 40px;
            height: 40px;
            vertical-align: middle;
            justify-content: center;
            align-items: center;
            z-index: 2;
            display: flex;
            background-color: transparent;
            transition: all 0.5s ease;
            border: 1px #e1cdbc solid;

            @media (max-width: 1365px) {
                right: 15px;
            }

            &:hover,
            &:focus {
                background-color: #e1cdbc;

                img {
                    filter: brightness(0) invert(1);
                }
            }

            img {
                width: 16px;
            }
        }
    }
}

.section-catalogo {
    margin-top: 105px;

    @media (max-width: 991px) {
        margin-top: 30px;
    }

    .container-color {
        background-color: rgba(178, 135, 64, 0.14);
    }

    .titulo-catalogo {
        padding: 45px 0px;

        @media (max-width: 991px) {
            padding: 30px 0px;
        }

        .text-titulo-catalogo {
            font-family: "Carentro DEMO";
            font-size: 41px;
            font-style: normal;
            font-weight: 400;
            line-height: 54px;
            letter-spacing: 0em;
            text-align: left;
            color: #5b1e20;
            position: relative;

            @media (max-width: 991px) {
                text-align: center;
            }

            .acute {
                position: absolute;
                left: 89px;
                top: -27.5px;

                img {
                    width: 7px;
                }
            }
        }

        .link-catalogo {
            margin-top: 7.5px;

            a {
                font-size: 15px;
                font-style: normal;
                font-weight: 300;
                line-height: 23px;
                letter-spacing: 0em;
                color: #b28740;
                transition: all 0.5s ease;
                display: flex;
                align-items: center;

                @media (max-width: 991px) {
                    justify-content: center;
                }

                &:hover,
                &:focus {
                    color: #5b1e20;

                    img {
                        filter: brightness(0);
                    }
                }

                img {
                    transition: all 0.5s ease;
                    width: 14px;
                }
            }
        }
    }

    .box-img-garrafas {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            margin-top: -60px;
            margin-bottom: 5px;
            max-width: 350px;

            @media (max-width: 991px) {
                margin-top: 0px;
                max-width: 250px;
                margin-bottom: 30px;
            }

            @media (min-width: 992px) {
                margin-left: 115px;
            }
        }
    }
}

.section-vinhos {
    position: relative;
    padding-bottom: 55px;

    @media (max-width: 991px) {
        padding-bottom: 25px;
    }

    .leaf {
        top: 80px;
        position: absolute;
        left: 70px;
        width: 112px;
        transform: scaleX(-1);

        @media (max-width: 991px) {
            width: 55px;
            left: 15px;
            top: 50px;
        }

        @media (min-width: 1366px) and (max-width: 1559px) {
            left: 115px;
        }

        @media (min-width: 1600px) and (max-width: 1919px) {
            left: 100px;
        }

        @media (min-width: 1920px) and (max-width: 2559px) {
            left: 260px;
        }

        @media (min-width: 2560px) {
            left: 580px;
        }
    }

    .titulo-section-vinhos {
        margin: 80px auto;
        max-width: 351px;

        @media (max-width: 991px) {
            margin: 30px auto;
        }

        .imagem-titulo {
            text-align: center;

            img {
                width: 129px;
            }
        }

        .texto-titulo {
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: center;
            color: #5b1e20;
            margin: 10px 0px;
        }

        .texto-titulo-2 {
            font-family: "Carentro DEMO";
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: 33px;
            letter-spacing: 0em;
            text-align: center;
            color: #2a1b15;
        }

        .texto-titulo-3 {
            margin-top: 20px;
            font-size: 15px;
            font-style: italic;
            font-weight: 300;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            color: #2a1b15;
        }
    }

    .box-vinho {
        margin-bottom: 25px;
        height: 426px;
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        @media (max-width: 991px) {
            margin-bottom: 15px;
            height: 350px;
        }

        .mascara-vinho {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.35);
            display: flex;
            align-items: center;
            justify-content: center;

            .flex-vinho {
                .titulo-vinho {
                    font-family: "Carentro DEMO";
                    font-size: 41px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 54px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #fff;
                    margin-bottom: 20px;
                }

                .link-vinho {
                    text-align: center;

                    button {
                        font-family: "Poppins", sans-serif;
                        padding: 9.5px 32px;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 300;
                        background-color: transparent;
                        line-height: 23px;
                        letter-spacing: 0em;
                        text-align: center;
                        color: #e1cdbc;
                        border: 1px solid #e1cdbc;
                        transition: all 0.5s ease;

                        &:hover,
                        &:focus {
                            padding-right: 27px;

                            img {
                                margin-left: 5px;
                            }
                        }

                        img {
                            width: 16px;
                            margin-left: 0px;
                            transition: all 0.5s ease;
                        }
                    }
                }
            }
        }
    }
}
